<!--门禁模块--访客人员记录-->
<template>
  <div id="visitorPeopleRecord" v-loading="loading" :element-loading-text="`${$t('visitorPeopleRecord.a1')}....`">
    <div class="header">
      <my-headertitle>{{ $t("visitorPeopleRecord.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="[16,16]">
          <!-- 受访人信息 -->
          <a-col :span="8">
            <a-input-group compact>
              <a-select v-model="form.condition1" style="width:50%" :placeholder="$t('visitorBookingRecord.a52')">
                <a-select-option v-for="item in conditions1" :key="item.key" >
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-input v-model="form.value1" :placeholder="Interviewee" style="width:50%" allow-clear/>
            </a-input-group>
          </a-col>
          <!-- 认证类型 -->
          <!-- <a-col :span="4">
            <a-select v-model="form.condition6" style="width:100%" :placeholder="$t('visitorBookingRecord.a19')">
              <a-select-option v-for="item in conditions6" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col> -->
          <!-- 拜访事由 -->
          <a-col :span="4">
            <a-select v-model="form.condition6" style="width:100%" :placeholder="$t('visitorBookingRecord.a19')" >
              <a-select-option v-for="item in conditions6" :key="item.key">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- 工单状态 -->
          <a-col :span="4">
            <a-select v-model="form.condition4" style="width:100%" :placeholder="$t('visitorBookingRecord.a15')" >
              <a-select-option v-for="item in conditions4" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- 到访状态 -->
          <a-col :span="4">
            <a-select v-model="form.condition5" style="width:100%" :placeholder="$t('visitorBookingRecord.a16')" >
              <a-select-option v-for="item in conditions5" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
           <!-- 备注 -->
           <a-col :span="4">
            <!-- $t('visitorBookingRecord.a16') -->
            <a-input v-model="form.memo" style="width:100%" :placeholder="$t('visitorRecord.a24')" allow-clear >
              
            </a-input>
          </a-col>
          <!-- 访客信息 -->
          <a-col :span="8">
            <a-input-group compact>
              <a-select v-model="form.condition2" style="width:50%" :placeholder="$t('visitorBookingRecord.a51')">
                <a-select-option v-for="item in conditions2" :key="item.key">
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-input v-model="form.value2" :placeholder="visitor" style="width:50%" allow-clear/>
            </a-input-group>
          </a-col>
          <!-- 时间 -->
          <a-col :span="14">
            <a-input-group compact>
              <!-- 时间类型选择 -->
              <div style="width:30%">
                <a-select v-model="form.condition3" style="width:100%" :placeholder="$t('visitorBookingRecord.a5')" @change="dateTypeSelect">
                  <a-select-option v-for="item in conditions3" :key="item.key">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </div>
              <!-- 初始日期 -->
              <div style="width:30%">
                <a-date-picker v-model="form.date1" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" :disabled-date="disabledStartDate"  style="width:100%"/>
              </div>
              <!-- 过渡线 -->
              <div style="width:5%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style="width:30%">
                <a-date-picker  v-model="form.date2" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width:100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="inquire">{{ $t("visitorBookingRecord.a8")}}</a-button>
          </a-col>
        </a-row>
      </div>
    </div>

    <!-- 表格区域 -->
    <div class="main" ref="main">
      <my-tabletitle>
        {{ $t("visitorPeopleRecord.a9") }}
        <template v-slot:name>
          <!-- CSV导出 -->
          <a-button type="primary" icon="file-text" size="small" ghost :disabled="list.length == 0" @click="csvExport">
            {{ $t("alarm.a51") }}
          </a-button>
        </template>
      </my-tabletitle>
      <!-- 表格 -->
      <a-table :columns="columns" :dataSource="list" :pagination="pagination" :scroll="size" :rowKey="(record) => record.key">
        <template slot="operate" slot-scope="text, record">          
          <div>
            <a-popover placement="left">
              <template slot="content">
                <!-- 详情 -->
                <a-button type="primary" style="margin-right: 15px" @click="() => onCheckDetails(record)">{{ $t("visitorPeopleRecord.a29") }}</a-button>
                <!-- 清除 -->
                <a-button v-if="record.time != null" type="primary" @click="() => onClearStrandVisitor(record)">{{ $t("visitorPeopleRecord.a51") }}</a-button>
              </template>
              <a>{{ $t("visitorPeopleRecord.a52") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>

      <!-- 详情对话框 -->
      <a-modal  v-model="visible" :title="modalName"  width=1300px  :body-style="bodystyle" :footer="null" @ok="handleOk" @cancel="handleCancel">
        <my-visitorDetailsModel :visitorDetails="visitorDetails"></my-visitorDetailsModel>
      </a-modal>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import visitorDetailsModel from "./visitorDetailsModel";
import { getVisitorPeopleList,getVisitorOrderInfo,getVisitorDictionary,clearStrandVisitors } from "../../api/visitor";
import { tableSort, csvDownload } from "../../utils/utils";
import moment from "moment";

// const bodystyle = {
//         height: '480px',
//         overflow: 'hidden',
//         overflowY: 'scroll',
//       }

export default {
  name: "visitorPeopleRecord",
  data() {
    return {   
      bodystyle:{
        height: '720px',
        // overflow: 'hidden',
        // overflowY: 'scroll',
      },
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
      modalName:this.$t("visitorPeopleRecord.a29"),
      visible: false,
      endOpen: false,
      visitorDetails:{},
      form:{
            date1: moment().startOf("day"),
            date2: moment().endOf("day"),
            value1: undefined,
            value2: undefined,
            condition1: "personPhone",
            condition2: "visitorPhone",
            condition3: 0,   
            condition4: '',
            condition5: '',
            condition6: '', 
            stranded:0,
            memo:'',
        },
      conditions1:[
                  {key:"personCode",title:this.$t("visitorPeopleRecord.a22")},
                  {key:"personName",title:this.$t("visitorPeopleRecord.a23")},
                  {key:"personPhone",title:this.$t("visitorPeopleRecord.a28")}],
      conditions2:[
                  {key:"visitorNo",title:this.$t("visitorPeopleRecord.a21")},
                  {key:"visitorName",title:this.$t("visitorPeopleRecord.a12")},
                  {key:"visitorPhone",title:this.$t("visitorPeopleRecord.a13")},
                  {key:"visitorCompany",title:this.$t("visitorPeopleRecord.a14")},
                  {key:"visitorCarNo",title:this.$t("visitorBookingRecord.a21")},],
      conditions3:[
                  {key:0,title:this.$t("visitorPeopleRecord.a17")},
                  {key:1,title:this.$t("visitorPeopleRecord.a18")},
                  {key:2,title:this.$t("visitorPeopleRecord.a26")}],
      conditions4:[
                  // {key:"",title:""},
                  //  {key:0,title:this.$t("visitorPeopleRecord.a30")},
                  // {key:1,title:this.$t("visitorPeopleRecord.a31")},
                  // {key:2,title:this.$t("visitorPeopleRecord.a32")},
                  // {key:3,title:this.$t("visitorPeopleRecord.a33")},
                  // {key:4,title:this.$t("visitorPeopleRecord.a34")}
                  ],
      conditions5:[
                  // {key:"",title:""},
                  // {key:1,title:this.$t("visitorPeopleRecord.a35")},
                  // {key:2,title:this.$t("visitorPeopleRecord.a36")},
                  // {key:3,title:this.$t("visitorPeopleRecord.a37")},
                  // {key:4,title:this.$t("visitorPeopleRecord.a38")},
                  ],
      conditions6:[
                  {key:"",title:this.$t("visitorBookingRecord.a53")},
                  {key:0,title:this.$t("visitorBookingRecord.a39")},
                  {key:1,title:this.$t("visitorBookingRecord.a40")},
                  {key:2,title:this.$t("visitorBookingRecord.a41")},
                  {key:3,title:this.$t("visitorBookingRecord.a42")},
                  {key:4,title:this.$t("visitorBookingRecord.a43")},
                  {key:5,title:this.$t("visitorBookingRecord.a44")},
                  {key:6,title:this.$t("visitorBookingRecord.a45")},
                  {key:7,title:this.$t("visitorBookingRecord.a46")},
                  {key:8,title:this.$t("visitorBookingRecord.a47")}
                  ],
      loading: false,
      startDate: undefined,
      rules: {
        date1: [
          {
            required: true,
            message: () => {
              return this.$t("visitorPeopleRecord.a10");
            },
            trigger: "change",
          },
        ],
        date2: [
          {
            required: true,
            message: () => {
              return this.$t("visitorPeopleRecord.a10");
            },
            trigger: "change",
          },
        ],
      },
      size: {  x: 1200,y: 240 },
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("visitorPeopleRecord.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("visitorPeopleRecord.a12"),
          dataIndex: "visitorName",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorName");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a13"),
          dataIndex: "visitorPhone",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorPhone");
          },
          width: 120,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a21"),
          dataIndex: "visitorNo",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorNo");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a14"),
          dataIndex: "visitorCompany",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorCompany");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a21"),
          dataIndex: "visitorCarNo",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorCarNo");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t('visitorRecord.a24'),//'备注',
          dataIndex: "memo",
          sorter: (a, b) => {
            return tableSort(a, b, "memo");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a53"),//'进门设备',
          dataIndex: "inEquipment",
          sorter: (a, b) => {
            return tableSort(a, b, "inEquipment");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a54"),//'出门设备',
          dataIndex: "outEquipment",
          sorter: (a, b) => {
            return tableSort(a, b, "outEquipment");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a17"),
          dataIndex: "bookStartTime",
          sorter: (a, b) => {
            return tableSort(a, b, "bookStartTime");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a18"),
          dataIndex: "bookEndTime",
          sorter: (a, b) => {
            return tableSort(a, b, "bookEndTime");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a15"),
          dataIndex: "orderStatusName",
          sorter: (a, b) => {
            return tableSort(a, b, "orderStatusName");
          },
          width: 120,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a16"),
          dataIndex: "visitorStatusName",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorStatusName");
          },
          width: 120,
          // ellipsis: true,
        },
        
        // {
        //   title: this.$t("visitorPeopleRecord.a19"),
        //   dataIndex: "passTypeName",
        //   sorter: (a, b) => {
        //     return tableSort(a, b, "passTypeName");
        //   },
        //   width: 120,
        //   // ellipsis: true,
        // },

        // {
        //   title: this.$t("visitorPeopleRecord.a20"),
        //   dataIndex: "time",
        //   sorter: (a, b) => {
        //     return tableSort(a, b, "time");
        //   },
        //   width: 150,
        //   // ellipsis: true,
        // },

        {
          title: this.$t("visitorBookingRecord.a19"),
          dataIndex: "visitorReason",
          customRender: (text, record, index) =>{
            return this.getVisitReasonName(record,text,index);
          },
          sorter: (a, b) => {
            return tableSort(a, b, "visitorReason");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a20"),
          dataIndex: "visitorRemark",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorRemark");
          },
          width: 200,
          // ellipsis: true,
        },
         
        {
          title: this.$t("visitorPeopleRecord.a22"),
          dataIndex: "personNo",
          sorter: (a, b) => {
            return tableSort(a, b, "personNo");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a23"),
          dataIndex: "personName",
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a25"),
          dataIndex: "personPhone",
          sorter: (a, b) => {
            return tableSort(a, b, "personPhone");
          },
          width: 120,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorPeopleRecord.a24"),
          dataIndex: "personGR",
          sorter: (a, b) => {
            return tableSort(a, b, "personGR");
          },
          width: 300,
          // ellipsis: true,
        },        
        {
          title: this.$t("throughrecords.a27"),
          dataIndex: "operate",
          scopedSlots: { customRender: "operate" },
          width: 100,
          fixed: "right",
        },
      ],
    };
  },
  computed:{
    // 受访人信息输入框占位符显示内容
    Interviewee: function (){
      let conditions1 = this.form.condition1
      if(conditions1 === 'managerCode'){
        return this.$t("visitorBookingRecord.a22")
      }else if(conditions1 === 'personName'){
        return this.$t("visitorBookingRecord.a23")
      }else if(conditions1 === 'personPhone'){
        return this.$t("visitorBookingRecord.a28")
      }else{
        return ''
      }
    },
    // 访客信息输入框占位符显示内容
    visitor: function (){
      let conditions2 = this.form.condition2
      if(conditions2 === 'visitorName'){
        return this.$t("visitorBookingRecord.a12")
      }else if(conditions2 === 'visitorPhone'){
        return this.$t("visitorBookingRecord.a13")
      }else if(conditions2 === 'visitorCompany'){
        return this.$t("visitorBookingRecord.a14")
      }else if(conditions2 === 'visitorCarNo'){
        return this.$t("visitorBookingRecord.a21")
      }else{
        return ''
      }
    }
  },
  activated(){
    this.list = [];
    this.form={
            date1: moment().startOf("day"),
            date2: moment().endOf("day"),
            value1: undefined,
            value2: undefined,
            condition1: "personPhone",
            condition2: "visitorPhone",
            condition3: 0,   
            condition4: '',
            condition5: '',
            condition6: '', 
            stranded:0,
            memo:'',
        };
  },
  mounted() {
    this.getVisitorDictionary();
    this.tableSize();
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods: {
    moment,
    onChange(e) {
      if(e.target.checked)
        this.form.stranded=1;
      else
        this.form.stranded=0;
    },
    handleOk(e) {
      console.log("handleOk e:",e);
      this.visible = false;
    },
    handleCancel(){
      this.visible = false;
    },
    //消除滞留访客
    onClearStrandVisitor(record){
      console.log("onClearStrandVisitor record:",record);
      this.clearStrandVisitors(record.peopleId);
    },
    //查看记录详情
    onCheckDetails(record){
      console.log("onCheckDetails record:",record);
      this.visible = true;
      this.visitorDetails = {};
      let siteId = this.$route.query.id;
      this.getVisitorOrderDetails(siteId,record.orderId);
    },

    clearStrandVisitors(peopleId){
      
      let par={clearItems:peopleId};
      console.log("clearStrandVisitors par:",par);
      clearStrandVisitors(par)
        .then((res) => {              
          this.loading = false;
          let { data } = res;  
          console.log("clearStrandVisitors res:",data);
          if(data){
            this.$message.info(this.$t('general.a6')); 
          }           
          else{
            this.$message.error(this.$t('general.a7')); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getVisitorOrderDetails(siteId,orderId){
      getVisitorOrderInfo(siteId,orderId)
        .then((res) => {              
          this.loading = false;
          if(res.errorCode=='00' || res.errorCode=='03'){
             
            let { data } = res;  
            let idx =1;
            data.visitors.forEach(element => {
              element.no = idx;
              idx++;
            });
            idx =1;
            data.events.forEach(element => {
              element.no = idx;
              idx++;
            });
            this.visitorDetails=data;
            
            console.log("getVisitorOrderDetails res:",data);
          }else{
              this.$message.error(res.msg); 
          }


        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 表格大小调节
    tableSize() {
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 160;
      this.size.y = tableHeight;
      // console.log(tableHeight);
    },
    
    //获取访客参数字典信息列表
    getVisitorDictionary(){
      this.loading = true
      getVisitorDictionary(this.$route.query.id)
            .then((res) => {              
              this.loading = false;
              let { data } = res;           
              console.log("getVisitorDictionary data:",data);
              if(data!=null&&data.length>0){
                for(let i=0;i<data.length;i++){
                  switch(data[i].kind){
                    case "VISITOR_PASS_TYPE":  
                      // let blankPassType={kind: "VISITOR_PASS_TYPE", name: this.$t("visitorBookingRecord.a56"), value: ""};
                      // this.conditions6.push(blankPassType);
                      // this.conditions6=this.conditions6.concat(data[i].data);
                      break;
                    case "VISITOR_ACCESS_STATUS":
                      let blankAccessStatus={kind: "VISITOR_ACCESS_STATUS", name: this.$t("visitorBookingRecord.a54"), value: ""};
                      this.conditions5.push(blankAccessStatus);
                      this.conditions5=this.conditions5.concat(data[i].data);
                      break;
                    case "VISITOR_ORDER_STATUS":
                      let blankOrderStatus={kind: "VISITOR_ORDER_STATUS", name: this.$t("visitorBookingRecord.a55"), value: ""};
                      this.conditions4.push(blankOrderStatus);
                      this.conditions4=this.conditions4.concat(data[i].data);
                      break;
                  }
                }
              }
              // console.log("getVisitorDictionary conditions6:",this.conditions6);
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
    },
    // 条件查询方法
    inquire() {
      this.loading = true
      const date1 = this.form.date1
      const date2 = this.form.date2
      if(!date1 || !date2){
        this.form.date1 = moment().startOf("day")
        this.form.date2 = moment().endOf("day")
      }
      let par = {
        siteId:this.$route.query.id,
        tenantId:0,
        dateSelected:this.form.condition3,
        strBookStartTimeStar: this.form.condition3==0?this.form.date1.format("YYYY/MM/DD HH:mm:ss"):"",
        strBookStartTimeEnd: this.form.condition3==0?this.form.date2.format("YYYY/MM/DD HH:mm:ss"):"",  
        strBookEndTimeStar:this.form.condition3==1?this.form.date1.format("YYYY/MM/DD HH:mm:ss"):"",
        strBookEndTimeEnd:this.form.condition3==1?this.form.date2.format("YYYY/MM/DD HH:mm:ss"):"", 
        strDateTimeStar:this.form.condition3==2?this.form.date1.format("YYYY/MM/DD HH:mm:ss"):"",
        strDateTimeEnd:this.form.condition3==2?this.form.date2.format("YYYY/MM/DD HH:mm:ss"):"", 
        personName:this.form.condition1=="personName"?this.form.value1:"",
        personPhone:this.form.condition1=="personPhone"?this.form.value1:"",
        personCode:this.form.condition1=="personCode"?this.form.value1:"",
        visitorName:this.form.condition2=="visitorName"?this.form.value2:"",
        visitorPhone:this.form.condition2=="visitorPhone"?this.form.value2:"",
        visitorCompany:this.form.condition2=="visitorCompany"?this.form.value2:"",
        visitorCarNo:this.form.condition2=="visitorCarNo"?this.form.value2:"",
        visitorNo:this.form.condition2=="visitorNo"?this.form.value2:"",
        orderStatus:this.form.condition4,
        visitorStatus:this.form.condition5,
        visitorReason:this.form.condition6,
        stranded:this.form.stranded,
        memo:this.form.memo,
      };
      console.log("getVisitorPeopleList par:",par);
      getVisitorPeopleList(par)
        .then((res) => {              
          this.loading = false;

          if(res.errorCode=='00' || res.errorCode=='03'){
              let { data } = res;
              console.log("getVisitorPeopleList res:",data);
              if(data!=null){  
                this.list.length=0;
                let i = 1;
                data.forEach(element => {
                  element.key = i;
                  this.list.push(element);
                  i++;
                });       
              }  
              else
                this.list.length=0;            
              console.log("getVisitorPeopleList list:",this.list);
          }else{
              this.$message.error(res.msg); 
          }

          
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value) {
      const type = this.form.condition3
      const currentDateValue = moment()
      const startDateValue = moment(value._d)
      const endDateValue = this.form.date2
      const maxDateValue = moment(value._d).add(1, 'months')
      if(value){
        if(type === 0){
          if(endDateValue > maxDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
          }else if(startDateValue > endDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
          }
        }else{
          if(endDateValue > maxDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
          }else if(startDateValue > endDateValue){
            if(currentDateValue > maxDateValue){
              this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
            }else{
              this.form.date2 = moment().endOf('day')
            }
          }
        }
      }
    },
    // 时间类型选择回调，根据不同的类型调整初始/终止日期时间的值
    dateTypeSelect(value){
      const currentDateValue = moment()
      const startDateValue = moment(this.form.date1._d)
      const endDateValue = moment(this.form.date2._d)
      if(value !== 0){
        if(startDateValue > currentDateValue){
          this.form.date1 = moment().startOf('day')
          this.form.date2 = moment().endOf('day')
        }else if(endDateValue > currentDateValue){
          this.form.date2 = moment().endOf('day')
        }
      }
    },
    // 初始日期/时间的不可选范围
    disabledStartDate(startValue) {
      const type = this.form.condition3
      const currentDateValue = moment().endOf('day')
      if(type === 0){
        return false
      }else {
        return startValue > currentDateValue
      }
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const type = this.form.condition3
      const starDatetValue = this.form.date1
      const currentDateValue = moment().endOf('day')
      if(type === 0){
        return starDatetValue > endValue || endValue > moment(starDatetValue._d).add(1,'months')
      }else {
        return endValue > currentDateValue || endValue > moment(starDatetValue._d).add(1,'months') || starDatetValue > endValue
      }
    },
    // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD");
      const date2 = this.form.date2.format("YYYYMMDD");
      const name =this.$t("visitorPeopleRecord.a2");
      const fileName = `${date1}~${date2}${name}`;      
      csvDownload(this.list, this.columns, fileName);
    },
    getVisitReasonName(record,text,index){
      let item = undefined;
      this.conditions6.forEach(element => {
          if(element.key+""===record.visitorReason){
            item = element;
          } 
      });
      if(item){
          return item.title;
      }
      return (record.visitorReason?record.visitorReason:"-");
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-visitorDetailsModel":visitorDetailsModel,
  },
};
</script>

<style scoped>
#visitorPeopleRecord {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 161px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 161px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 10px;
  min-width: 1px;
}
.select{
  width: 150px;
}
.datePicker{
  width: 100px;
}

div /deep/.ant-form-item{
  margin-bottom: 0px;
}
</style>